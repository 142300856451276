<template>
  <div>
    <p class="h3">Notification Details</p>
    <div>
      <div v-if="!pageloading">
        <div class="row">
          <div class="col-md-8">
            <div class="card shadow mb-3">
              <div class="card-header" id="headingOne">
                <TextTitleDisplay
                  title="Category"
                  :content="currentItem.category"
                />
                <TextTitleDisplay
                  title="Subject"
                  :content="currentItem.subject"
                />
                <TextTitleDisplay title="Body" :content="currentItem.body" />
              </div>
              <div class="d-flex justify-content-end mx-2 py-4">
                <button
                  @click="goBack"
                  type="submit"
                  class="btnd btn-success text-light"
                >
                  Go black
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Spinner v-if="pageloading" />
    </div>
  </div>
</template>

<script>
import Calls from "@/Calls";
import Spinner from "@/components/Spinner";
import NotificationService from "../../services/notifications.service";
import TextTitleDisplay from "../../components/texts/TextTitleDisplay.vue";
export default {
  name: "AllSupport",
  components: { Spinner, TextTitleDisplay },
  data() {
    return {
      currentItem: {},
      allNotifications: [],
      subCategory: [],
      pageloading: true,
      currentEdit: {},
      loading: false,
      currentId: "",
    };
  },
  filters: {
    formartNumber(val) {
      return Calls.formartNumber(val);
    },
    formartDate(val) {
      return Calls.formartDate(val);
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async fetch() {
      try {
        let response = await NotificationService.fetchAll();
        this.allNotifications = response.data.rows;
      } catch (error) {
        this.$toast.open({
          message: error.msg,
          type: "error",
          position: "top-right",
          duration: 2000,
          pauseOnHover: true,
        });
      }
    },
  },
  async created() {
    let id = this.$route.params.id;
    this.currentId = id;
    await this.fetch();
    this.allNotifications.forEach((item) => {
      if (item.id == id) {
        this.currentItem = item;
      }
    });
    this.pageloading = false;
  },
};
</script>

<style scoped></style>
